<template>
  <AntTooltip placement="bottom">
    <template #title>
      下載線下版 API Doc PDF：<br>Seperate Window > 開啟預覽視窗 > Offline Mode > 右鍵「列印」> 配置選擇「風景 (橫式)」> 儲存
    </template>
    <AntButton type="primary" @click="onToggleOfflineMode" class="offline-btn">
      {{ offlineMode ? 'Online' : 'Offline' }} Mode
    </AntButton>
  </AntTooltip>
  <RedocWrapper :specOrSpecUrl="spec" :scrollTop="scrollTop" :offlineMode="offlineMode" />
</template>

<script>
import {
  ref,
  reactive,
  onMounted,
  onUnmounted,
  nextTick,
} from 'vue'

import {
  Button as AntButton,
  Tooltip as AntTooltip,
  notification as antNotification,
} from 'ant-design-vue'

import yamljs from 'yamljs'

import RedocWrapper from '@/components/RedocWrapper'

export default {
  name: 'RedocPreviewer',
  components: {
    RedocWrapper,
    AntButton,
    AntTooltip,
  },
  setup() {
    const spec = reactive({})
    const lastEditorValue = ref('')
    const offlineMode = ref(false)
    const scrollTop = ref()

    const onToggleOfflineMode = () => { offlineMode.value = !offlineMode.value }

    const changeSpec = async ({ openApiEditorValue, scrollToTop }) => {
      const currentScrollTop = document.documentElement.scrollTop
      scrollTop.value = scrollToTop ? 0 : currentScrollTop

      try {
        Object.assign(spec, yamljs.parse(openApiEditorValue))
      } catch (error) {
        const { message, parsedLine, snippet } = error
        antNotification.error({
          message: `Error: ${message}`,
          description: `${parsedLine ? `#${parsedLine}` : ''}${snippet ? ` ${snippet}` : ''}`,
          duration: 5,
        })
      }

      lastEditorValue.value = openApiEditorValue
    }

    const handleMessageFromEditor = ({ data }) => { if (data.openApiEditorValue) changeSpec(data) }

    onMounted(async () => {
      await nextTick(() => {
        window.addEventListener('message', handleMessageFromEditor)
        window.parent.postMessage({ isPreviewerReady: true }, '*')
      })
    })

    onUnmounted(() => {
      window.removeEventListener('message', handleMessageFromEditor)
    })

    return {
      spec,
      scrollTop,
      offlineMode,
      onToggleOfflineMode,
    }
  },
}
</script>

<style scoped>
.offline-btn {
  position: fixed;
  z-index: 1;
}

@media print {
  .offline-btn {
    display: none;
  }
}
</style>
